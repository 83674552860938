<template>
  <div>
    <!--begin::Row-->
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-custom gutter-b">
            <div class="card-header border-0">
                <div class="card-title">
                <h3 class="card-label">Lihat Tiket</h3>
                </div>
              </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Perihal</th>
                                          <th>Jenis Pengaduan</th>
                                          <th>Petugas</th>
                                          <th>Tanggal</th>
                                          <th>Status</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in ticket" :key="row.id">
                                          <td></td>
                                          <td>{{row.tentang}}</td>
                                          <td>
                                              {{row.jenis_pengaduan}}
                                          </td>
                                          <td>
                                              <div v-if="row.penerima === null">
                                                  Belum ada
                                              </div>
                                              <div v-else>
                                                  {{row.penerima}}
                                              </div>
                                          </td>
                                          <td>
                                            {{row.updated_at}}
                                          </td>
                                          <td>
                                            {{row.status}}
                                          </td>
                                          <td>
                                            <div v-if="user.id_role === 'admin_helpdesk'">
                                              <b-button size="sm" @click="$router.push('/support/detail-ticket/' + row.id)" variant="success"><i class="flaticon-eye"></i></b-button>
                                            </div>
                                            <div v-else>
                                              <b-button size="sm" @click="$router.push('/support/detail-tickets/' + row.id)" variant="success"><i class="flaticon-eye"></i></b-button>
                                            </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
            <!-- Tambah -->
        </div>
    </div>
  </div>
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';

export default {
  data() {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        test:'hai',
        ticket:[],
      }
  },
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lihat Tiket" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    loaddata(){
      if (this.user.id_role === 'helpdesk') {
        axios.get(this.url+"/tiket?filter=idpenerima,=,"+ this.user.id+"&sort=id desc", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.ticket = response.data.data;
                this.initDatatable()
            }).catch(error=>{
                return error
                });
      } else {
        axios.get(this.url+"/tiket?sort=id desc", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.ticket = response.data.data;
                this.initDatatable()
            }).catch(error=>{
                return error
                });
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
